import React from 'react';
import Post from './Post.js';
import './styles/Blog.scss';

class Blog extends React.Component{
    render(){
        return(
            <div className="Blog" id="blog">
                <Post/>
                <Post/>
                <Post/>
                <Post/>
                <Post/>
                <Post/>
            </div>
        );
    }

}

export default Blog;