import React from 'react';
import './styles/Post.scss';
import post1 from './images/post1.jpeg';

class Post extends React.Component{
    render(){
        return(
            <div className="Post">
                    <div className="Post_imageContainer">
                        <img className="Post__image" src={post1} alt="" />
                        </div>
                    <p className="Post__description">
                        Lorem, i numquam perspi  numquam perspiciatis
                        </p>
            </div>
        );
    }
}

export default Post;