import React from 'react';
import './styles/Profile.scss';


class Profile extends React.Component{
    render(){
        return(
            <div className="ProfileContainer" id="profile">
                <div className="Profile__personal">
                    <div className="Profile__item">President of the Political School Commission at the Political Party Acción Popular, Arequipa,  Perú, 2020</div>
                    <div className="Profile__item">President of the Ethic Commission at the Political Party Acción Popular, Arequipa, Perú, 2019</div>
                    <div className="Profile__item">Young Parliamentarian for Tacna Region, Perú Government and Hanns Seidel Foundation, Perú, 2015</div>
                    <div className="Profile__item">Scholarship recipient to study at RWTH AACHEN, Germany 2012</div>
                    <div className="Profile__item">Scholarship recipient to study at Universidad Autónoma de Baja California ,Mexico 2010</div>
                    <div className="Profile__item">President at the Student Center of Computer Science, Chile, 2010</div>
                    <div className="Profile__item">Winner of the IBM competition 'My Smart City', Chile, 2010</div>
                </div>
                <div className="Profile__professional">
                    <div className="Profile__item">CEO of PoliticosPeru.pe, Perú 2020 - 2021</div>
                    <div className="Profile__item">Professor at Universidad la Salle, Arequipa, Perú,  2015 - 2020</div>
                    <div className="Profile__item">Data Base Specialist, Orden Hospitalaria San Juan de Dios, Perú 2015</div>
                    <div className="Profile__item">Research Student, Project Robotic Arm drived by Neural signals, Universidad de Tarapacá, Chile 2014</div>
                    <div className="Profile__item">Web Developer, Library Universidad de Tarapacá, Chile 2014</div>
                    <div className="Profile__item">Student Mobile App Developer, RWTH University, Aachen- Germany, 2013 - 2014</div>
                    <div className="Profile__item">Student Assistant  Basic programming, Operative Systems, Computer Architecture, Universidad de Tarapacá  2011 - 2012</div>
                    <div className="Profile__item">Teacher of Digital Literacy, Un Techo Para Chile 2011</div>
                </div>
            </div>
        );
    }
}

export default Profile;