import React from 'react';
import './styles/Ngo.scss';
import img1 from './images/img1.jpg';
import img4 from './images/img2.png';
import img2 from './images/img3.jpg';
import img5 from './images/img4.jpg';
import img3 from './images/img5.jpg';
import img6 from './images/img6.jpg';


class Ngo extends React.Component{
    render(){
        return(
            <div className="Ngo" id="ngo">
                <div>NGOs</div>
                <div className="Ngo__grid"> 
                    <div className="Ngo__item Ngo__itemOne">
                        <img className="Ngo__image" src={img1} alt="Acción Popular"/>
                    </div>
                    <div className="Ngo__item Ngo__itemTwo">
                    <img className="Ngo__image"  src={img2} alt="Acción Popular" />
                    </div>
                    <div className="Ngo__item Ngo__itemThree">
                    <img  className="Ngo__image" src={img3} alt="Acción Popular" />
                    </div>
                    <div className="Ngo__item Ngo__itemFour">
                    <img className="Ngo__image"  src={img4} alt="Acción Popular" />
                    </div>
                    <div className="Ngo__item Ngo__itemFive">
                    <img className="Ngo__image"  src={img5} alt="Acción Popular" />
                    </div>
                    <div className="Ngo__item Ngo__itemSix">
                    <img className="Ngo__image"  src={img6} alt="Acción Popular" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Ngo;