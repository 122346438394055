import React from 'react';



class Tag extends React.Component{
    render(){
        return(
                <span className="tag__element">{this.props.tag}</span>
        );
    }
}

export default Tag;