import React from 'react';
import New from './New';
import './styles/News.scss'

class News extends React.Component{
    render(){
        return(
            <div className="News" id="news">
                <h1 className="News__title">news</h1>
                <New/>
                <New/>
                <New/>
                <button className="News__button">MORE</button>
            </div>
        );
    }
}

export default News;