import React from 'react';
import Logo from './images/favicon.png';
import './styles/Navbar.scss';

class Navbar extends React.Component{
    render(){
        return(
            <div className="Navbar">
                <img className="Navbar__logo" src={Logo} alt=""/>
                <nav className="Navbar__options">
                    <ul className="Navbar__list">
                        <li className="Navbar__item"><a href="#projects">Projects</a></li>
                        <li className="Navbar__item"><a href="#profile">Profil</a></li>
                        <li className="Navbar__item"><a href="#ngo">NGO</a></li>
                        <li className="Navbar__item"><a href="#news">News</a></li>
                        <li className="Navbar__item"><a href="#blog">Blog</a></li>
                        <li className="Navbar__item"><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        )
    }
}
export default Navbar;