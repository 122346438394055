import React from 'react';
import Project from './Project.js';
import './styles/Projects.scss';


//TODO manage que width of a item
//TODO manage when the windows start with a width larger than the number of elements
class Projects extends React.Component{

    state={
        error: null,
        loading: true,
        data: undefined,
        hidePartRight:0,
        hidePartLeft:0,
        windowSize:0,
        data:null,
        elements:0,
        rest:0,
        flag:false,
    };

    resize=()=>{
        let width= window.innerWidth;
        console.log("rezise***************");
        let item=document.getElementsByClassName("Projects__arrow");
        if((this.state.hidePartLeft ==0 && this.state.hidePartRight == 0) && (width > ((this.state.elements * 230) + 150)) )
        {
            item.item(0).style.display="none";
            item.item(1).style.display="none";
            let listProjects=document.getElementById("projects");
            listProjects.style.display="flex";
            listProjects.style.justifyContent="center";

        }else{
            let item=document.getElementsByClassName("Projects__arrow");
            item.item(0).style.display="block";
            item.item(0).style.left="0";
            item.item(1).style.display="block";
            
            console.log("display block");
            let difference=width - this.state.windowSize;
            /* minimizing the windows */
            if(this.state.windowSize < width){
                if(difference>=1){
                    if((this.state.hidePartRight ==0 || this.state.hidePartRight < 0) && this.state.hidePartLeft > 0){
                        let item=document.getElementById("projectItems");
                        if(this.state.hidePartLeft < difference){
                            item.style.transform=`translateX(${0}px)`;
                            this.setState({
                                hidePartLeft: 0,
                                windowSize: width
                            });
                        }
                        else{
                            let change=this.state.hidePartLeft - difference;
                            item.style.transform=`translateX(${- change}px)`;
                            this.setState({
                                hidePartLeft: this.state.hidePartLeft - difference,
                                windowSize: width
                            });
                        }
                        
                    }else{
                        if(this.state.hidePartRight > 0 && this.state.hidePartRight > difference ){
                            this.setState({
                                hidePartRight: this.state.hidePartRight - difference,
                                windowSize: width
                            });
                        }else if(this.state.hidePartRight <= difference ){
                            this.setState({
                                hidePartRight: 0,
                                windowSize: width
                            });
                        }
                }
            } /* maximazing */

        }else if(this.state.windowSize > width){
            console.log("+++state windows"+ this.state.windowSize );
            console.log("+++inner width"+ width );
            let difference=this.state.windowSize - width;
            if(difference>1){
                this.setState({
                    hidePartRight: this.state.hidePartRight + difference,
                    windowSize:width
                });
            }else{
                this.setState({
                    hidePartRight: this.state.hidePartRight + 1,
                    windowSize:width
                });
            }

        }

    }

    }
    getProjects= ()=>{
        try{
            fetch('https://innovastorga.com/consult.php',
            {method: 'get'})
            .then((response)=>{
                // console.log(`this is the : ${response.text()}`);
                return (response.text());
            })
            .then((response)=>{
                let elementsHideLeft=0;
                let elementsHideRight=0;
                let width=window.innerWidth;
                let elements= Math.round((width-55)/230);
                let restElements=width%230;
                console.log("ancho ventana "+ width);
                console.log("elementos que entran en la ventana" + elements);
                console.log("resto del margen "+restElements);
                var elementsResponse=JSON.parse(response).elements;
                elementsHideLeft= Math.round((elementsResponse.length - elements)/2);
                console.log("elements Hide left "+ elementsHideLeft);
                elementsHideRight=elementsResponse.length - elements - elementsHideLeft;
                console.log("elements Hide Right "+ elementsHideRight);


                this.setState({error:null, loading:false,
                    data: elementsResponse,
                    elements: elementsResponse.length,
                    hidePartLeft: (elementsHideLeft * 230),
                    hidePartRight: (elementsHideRight * 230) + restElements,
                    rest: restElements,
                    windowSize: width
                });
            }
            );

            // this.setState({error:null, loading:false});
        

            // fetch('https://innovastorga.com/consult.php', {
            //     method: 'get',
            //     // may be some code of fetching comes here
            // }).then(function(response) {
            //         if (response.status >= 200 && response.status < 300) {
            //             return response.text()
            //         }
            //         throw new Error(response.statusText)
            //     })
            //     .then(function(response) {
            //         console.log(response);
            //     })
            
        }
        catch(error){
            this.setState({error:error, loading:false});
        }
    }

    componentDidMount(){
        console.log("didMountComponent");
        this.getProjects();
        
        window.addEventListener('resize', this.resize);
    }

    componentDidUpdate(prevProps,prevState){

        if(this.state.data != null && !prevState.flag ){
            let projectItems=document.getElementById("projectItems");
            projectItems.style.transform=`translateX(${- prevState.hidePartLeft}px)`;
            this.setState({flag:true});
        }
        

        if(document.getElementById("1") == null){
            console.log("hidden hidden hidden hidden hidden");
        }
        console.log("component did update");
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
      }

    isHidden=(el)=> {
        return (el.offsetParent === null)
    }
    right=()=>{
        if(this.state.hidePartRight <= 0){
            return null;
        }
        else if(this.state.hidePartRight >0 && this.state.hidePartRight < 230){
            let item=document.getElementById("projectItems");
            let change=this.state.hidePartLeft + this.state.hidePartRight;
            item.style.transform=`translateX(${- (change)}px)`;
            console.log("rest"+this.state.hidePartRight);
            this.setState({
                hidePartLeft: change,
                hidePartRight: 0,
                rest: this.state.hidePartRight
              });
            
        }
        else{   
            let item=document.getElementById("projectItems");
            let change=this.state.hidePartLeft +230;
            item.style.transform=`translateX(${- change}px)`;
            this.setState({hidePartLeft: change,
                 hidePartRight: this.state.hidePartRight - 230,
                 });        
        }
    }
    left=()=>{
        if(this.state.hidePartLeft <= 0){
            return null;
        }
        // else if(this.state.hidePartRight == 0){
        //     console.log("there is not right part");
        //     let item=document.getElementById("projectItems");
        //     let change=this.state.hidePartLeft - 250 + 50;
        //     item.style.transform=`translateX(${- change}px)`;

        //     this.setState({marginLeft: change,
        //         hidePartLeft: this.state.hidePartLeft - 250,
        //         hidePartRight: 250,
        //         })
        // }
        else if(this.state.hidePartLeft % 230 != 0 && this.state.hidePartLeft < 230){
            this.state.rest= this.state.hidePartLeft % 230;
            let item=document.getElementById("projectItems");
            let change=this.state.hidePartLeft - (this.state.rest) ;
            item.style.transform=`translateX(${- change}px)`;
            console.log("I'm here");
            this.setState({
                hidePartLeft: this.state.hidePartLeft - this.state.rest,
                hidePartRight: this.state.hidePartRight + this.state.rest,
                })
        }
        else{
            let item=document.getElementById("projectItems");
            let change=this.state.hidePartLeft - 230;
            item.style.transform=`translateX(${- change}px)`;

            this.setState({     
                hidePartLeft:change,
                hidePartRight:this.state.hidePartRight + 230,
                })
        
        }
    }

    render(){
        console.log('render');
        console.log(this.state.loading);
        if(this.state.loading){
            console.log('loading');
            return(
                <h1>loading..</h1>
            );
        }
        if(this.state.error){
            console.log(this.state.error);
            return(
                <h1>{this.state.error}</h1>
            );
        }

        return(
         
            <div className="Projects" id="projects">
                <label onClick={this.left} className=" Projects__arrow Projects__arrow--left">{'<'}</label>
                <div className="Projects__content" id="projectsContent">          
                    <div className="Projects__items" id="projectItems">
                        {
                            this.state.data.map(function(element){                            
                                return(
                                    <Project key={new Date().getTime()+ element.id}  id={element.id} name={element.name} description={element.description} image={element.image} tags={element.tags}/>
                                )
                            })
                            
                        }
                    </div>
                </div>
                <label onClick={this.right} className=" Projects__arrow Projects__arrow--right">{'>'}</label>
            </div>
        );
    }


}

export default Projects;