import React from 'react';
import './styles/Hero.scss';

class Hero extends React.Component{
    render(){
        return(
            <div className="Hero">
                <p className="Hero__greeting">Hi, I'm</p>
                <p className="Hero__name">Angel Astorga</p>
            </div>    

        );
    }
}

export default Hero;