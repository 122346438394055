import logo from './logo.svg';
import React from 'react';
import { Route,Routes } from 'react-router';
import './App.scss';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Profile from './components/Profile';
import Ngo from './components/Ngo';
import News from './components/News';
import Blog from './components/Blog';
import Contact from './components/Contact';

function App() {
  
  return (
    <React.Fragment>
      <Routes>
      <Route path="/" element={<Hero/>}/>
      </Routes>
      {/* <Navbar />
      <div className='PageContainer'>
        <Hero/>
        <Projects/>
        <Profile/>
        <Ngo/>
        <News/>
        <Blog/>
        <Contact/>
      </div>  */}
      </React.Fragment>
  );
}

export default App;
