import React from 'react';
import Tag from './Tag';
import './styles/tag.scss';

class Tags extends React.Component{
    render(){
        return(

            <div className="tagContainer">
                {
                    this.props.tags.map(
                        function(element){
                            return(
                                <Tag tag={element}/>
                            );
                        }
                    )
                }
            </div>
        );
        }
}
export default Tags;