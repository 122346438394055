import React from 'react';
import './styles/Contact.scss';


class Contact extends React.Component{

    render(){
        return(
            <form action="" className="Contact" id="contact"> 
                <input className="Contact__email" type="text" name="email" placeholder="Email"/>
                <textarea className="Contact__message" type="text" name="message" placeholder="Message"/>
                <button className="Contact__button">SEND</button>
            </form>
        );
    }


}

export default Contact;