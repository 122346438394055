import React from 'react';
import './styles/New.scss';
import news1 from './images/news1.jpg';

class New extends React.Component{
    render(){
        return(
            <div className="New">
            <div className="New__imageContainer">
                <img className="New__image" src={news1} alt="" />
            </div>
            <p className="New__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur aut molestiae officia enim dolorem. Expedita.</p>   
        </div>
        );
    }
}

export default New;