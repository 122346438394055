import React from 'react';
import './styles/Project.scss';
import Tags from './Tags';

class Project extends React.Component{

    render(){
        return(
            <div className="Project">
                <div className="Project__top">
                    <img className="Project__image" src={this.props.image} alt="" />
                    <div className="Project__description">{this.props.description}</div>
                </div>
                <div className="Project__tags">
                    <Tags tags={this.props.tags}/>
                </div>
            </div>
        );
        }
}

export default Project;